import snakeCase from 'lodash/snakeCase';

import { CommunityPrivacy } from './types';

export enum COMMUNITIES {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=352462493
     */
    COMMUNITY_NAME = 'COMMUNITIES.COMMUNITY_NAME',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452578
     */
    POST_TYPE_DEFAULT = 'COMMUNITY_POST_TYPE_DEFAULT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452579
     */
    POST_TYPE_EVENT = 'COMMUNITY_POST_TYPE_EVENT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452580
     */
    POST_TYPE_IDEA = 'COMMUNITY_POST_TYPE_IDEA',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452564
     */
    COMMUNITY_POST_SELECT_PARENT_CONTENT = 'COMMUNITY_POST_SELECT_PARENT_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12240401
     */
    NAV = 'ADMIN.NAV.COMMUNITIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=142248095
     */
    NAVIGATION_EDIT = 'COMMUNITIES.NAVIGATION_EDIT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12287565
     */
    DASHBOARD = 'FRONT.COMMUNITY.DASHBOARD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=135462078
     */
    FILE_LIBRARY = 'COMMUNITIES.FILE_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13910017
     */
    MEDIA = 'FRONT.COMMUNITY.DRIVE_VIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30306332
     */
    CALENDAR = 'FRONT.COMMUNITY.APP_CALENDAR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=13736773
     */
    SELECT_FROM_DRIVE = 'ADMIN.COMMUNITY.SELECT_FROM_DRIVE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23494569
     */
    PRIVACY_OPEN = 'COMMUNITY.PRIVACY_OPEN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23494578
     */
    PRIVACY_RESTRICTED = 'COMMUNITY.PRIVACY_RESTRICTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23494590
     */
    PRIVACY_READ_ONLY = 'COMMUNITY.PRIVACY_READ_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23494624
     */
    PRIVACY_DESCRIPTION_ONLY = 'COMMUNITY.PRIVACY_DESCRIPTION_ONLY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24700000
     */
    STEP_VISIBILITY = 'ADMIN.COMMUNITY.SETUP.STEP.VISIBILITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24792828
     */
    SETUP_FIELD_COMMUNITY_URL = 'ADMIN.COMMUNITY.SETUP.FIELD.COMMUNITY_URL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=25194908
     */
    SETUP_FIELD_DESCRIPTION_HOMEPAGE = 'ADMIN.COMMUNITY.SETUP.FIELD.DESCRIPTION.HOMEPAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203146607
     */
    SELECT_COMMUNITIES = 'COMMUNITY.SELECT_COMMUNITIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=25194919
     */
    POST_CATEGORIES = 'ADMIN.COMMUNITY.SETUP.FIELD.CATEGORIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27770298
     */
    POST_TYPE_QUESTION = 'COMMUNITY_POST_TYPE_QUESTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=32615100
     */
    LAYOUT = 'SETTINGS_MENU_COMMUNITY_LAYOUT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=139217078
     */
    EDIT_NAVIGATION = 'COMMUNITIES.EDIT_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37995799
     */
    SEARCH_SCOPE_FOLLOWING = 'FRONT.COMMUNITY.SEARCH.SCOPE.FOLLOWING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=37995807
     */
    SEARCH_SCOPE_ALL = 'FRONT.COMMUNITY.SEARCH.SCOPE.ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=51707497
     */
    SEARCH_NO_RESULTS = 'FRONT.COMMUNITY.SEARCH.NO_RESULTS',
    /**
     * Usage: Message to use when displaying other services
     *
     * Translation: Other services
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58772756
     */
    SEARCH_NO_RESULTS_WITH_FILTER = 'FRONT.COMMUNITY.SEARCH.NO_RESULTS_WITH_FILTER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=58773759
     */
    SEARCH_SEE_ALL_COMMUNITIES = 'FRONT.COMMUNITY.SEARCH.SEE_ALL_COMMUNITIES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=67237420
     */
    NO_COMMUNITIES_DESCRIPTION = 'ADMIN.COMMUNITIES.NO_COMMUNITIES_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=105760077
     */
    CREATE_ANOTHER = 'COMMUNITIES.CREATE_ANOTHER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603424711
     */
    PRIVACY_RESTRICTED_DESCRIPTION = 'COMMUNITY.PRIVACY_SECRET_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603423902
     */
    PRIVACY_DESCRIPTION_ONLY_DESCRIPTION = 'COMMUNITY.PRIVACY_PRIVATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603417583
     */
    PRIVACY_READ_ONLY_DESCRIPTION = 'COMMUNITY.PRIVACY_MEMBERS_ONLY_CONTRIBUTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93802473
     */
    PRIVACY_OPEN_DESCRIPTION = 'COMMUNITY.PRIVACY_OPEN.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97328575
     */
    CONFIGURATION_DIALOG_SERVICES_LUMAPPS_TITLE = 'FRONT.COMMUNITY.CONFIGURATION_DIALOG.SERVICES.LUMAPPS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97328759
     */
    CONFIGURATION_DIALOG_SERVICES_LUMAPPS_DESCRIPTION = 'FRONT.COMMUNITY.CONFIGURATION_DIALOG.SERVICES.LUMAPPS.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=97478128
     */
    MIGHT_LOSE_CHANGES = 'FRONT.COMMUNITY.MIGHT_LOSE_CHANGES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108385124
     */
    FORCE_FOLLOW_ADDED_GROUPS_CANNOT_BE_REMOVED = 'COMMUNITIES.FORCE_FOLLOW_ADDED_GROUPS_CANNOT_BE_REMOVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=108385228
     */
    GROUPS_FOLLOWING_COUNT = 'COMMUNITIES.GROUPS_FOLLOWING_COUNT',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=53022728
     */
    CANNOT_CHANGE_RESTRICTED_COMMUNITY_STORAGE_ACTIVATION = 'ADMIN.COMMUNITY.SECURE_STORAGE.TOOLTIP_EDITION_MODE',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=53270119
     */
    VISIBILITY_CANNOT_BE_CHANGED_WITH_RESTRICTED_STORAGE = 'ADMIN.COMMUNITY.SECURE_STORAGE.VISIBILITY_OPTIONS_LABEL_DURING_EDITION',
    // eslint-disable-next-line lumapps/no-duplicate-translate-key-url
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12287565
     */
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    HOMEPAGE = 'FRONT.COMMUNITY.DASHBOARD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=103073586
     */
    POST_TYPES = 'FRONT.COMMUNITY.CONFIGURATION_DIALOG.POSTS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=30666761
     */
    MEMBER = 'FRONT.COMMUNITY.MEMBER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179644496
     */
    SELECTED_COMMUNITIES_COUNT = 'COMMUNITIES.SELECTED_COMMUNITIES_COUNT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=179752859
     */
    THERE_ARE_NO_COMMUNITIES = 'COMMUNITIES.THERE_ARE_NO_COMMUNITIES',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27386469
     */
    SELECT_OFFICE_GROUP = 'ADMIN.COMMUNITY.CREATION.PICK.GROUP.LABEL',
    /**
     * Lokalize link: https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=110500292
     */
    OFFICE_365_GROUP = 'COMMUNITIES.OFFICE_365_GROUP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=111007343
     */
    CREATE_MS_GROUPS_WITHOUT_OFFICE_365_TITLE = 'COMMUNITIES.CREATE_MS_GROUPS_WITHOUT_OFFICE_365_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603370705
     */
    CREATE_MS_GROUPS_WITHOUT_OFFICE_365_DESCRIPTION = 'COMMUNITIES.CREATE_MS_GROUPS_WITHOUT_OFFICE_365_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104977334
     */
    CREATE_MS_GROUPS_NEW_OFFICE_365_TITLE = 'COMMUNITIES.CREATE_MS_GROUPS_NEW_OFFICE_365_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104977625
     */
    CREATE_MS_GROUPS_NEW_OFFICE_365_DESCRIPTION = 'COMMUNITIES.CREATE_MS_GROUPS_NEW_OFFICE_365_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104977941
     */
    CREATE_MS_GROUPS_EXISTING_OFFICE_365_TITLE = 'COMMUNITIES.CREATE_MS_GROUPS_EXISTING_OFFICE_365_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=104978054
     */
    CREATE_MS_GROUPS_EXISTING_OFFICE_365_DESCRIPTION = 'COMMUNITIES.CREATE_MS_GROUPS_EXISTING_OFFICE_365_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203146254
     */
    CURRENT_COMMUNITY = 'COMMUNITY.CURRENT_COMMUNITY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203146235
     */
    COMMUNITY_SELECTION = 'COMMUNITY.COMMUNITY_SELECTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=12253357
     */
    SAVED = 'ADMIN.COMMUNITIES.SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603229957
     */
    COMMUNITY_MANAGERS = 'COMMUNITY.COMMUNITY_ADMINISTRATORS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375850393
     */
    MIGRATE_TO_SPACE = 'COMMUNITY.MIGRATE_TO_SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=294903383
     */
    BACK_TO_COMMUNITY = 'COMMUNITY.STATIC_PAGES_BACK_BUTTON',
}

enum SPACES_SPECIFIC_KEYS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=240368490
     */
    NAV = 'GLOBAL.FEATURE_SPACES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=251971091
     */
    SAVED = 'SPACES.SAVED_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=119169930
     */
    FEED = 'GLOBAL.FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277934429
     */
    HEADER_NOT_EDITABLE = 'SPACES.HEADER_NOT_EDITABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=475753147
     * */
    MANAGED_MEDIA_LIBRARY = 'SPACES.MANAGED_MEDIA_LIBRARY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603480093
     */
    PRIVACY_RESTRICTED_DESCRIPTION = 'SPACES.VISIBILITY_SECRET_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603474554
     */
    PRIVACY_DESCRIPTION_ONLY_DESCRIPTION = 'SPACES.VISIBILITY_PRIVATE_DESCRIPTION',
    /**
     * PRIVATE DESCRIPTION ON LANDING PAGE (FOR END USERS)
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603266077
     */
    PRIVACY_DESCRIPTION_ONLY_DESCRIPTION_SPACED = 'SPACES.PRIVATE_SPACE_LANDING_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603474473
     */
    PRIVACY_READ_ONLY_DESCRIPTION = 'SPACES.VISIBILITY_MEMBERS_ONLY_CONTRIBUTION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=267595412
     */
    PRIVACY_OPEN_DESCRIPTION = 'SPACES.OPEN_VISIBILITY_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292295292
     */
    FEED_IS_EMPTY = 'SPACES.FEED_IS_EMPTY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292295676
     */
    CREATE_PUBLICATION_TO_ENGAGE = 'SPACES.CREATE_PUBLICATION_TO_ENGAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=292295815
     */
    NEW_PUBLICATIONS_LISTED_HERE = 'SPACES.NEW_PUBLICATIONS_LISTED_HERE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7244407
     */
    COMMUNITY_MANAGERS = 'ADMIN.NAV.INSTANCE_ADMINS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=351888564
     */
    CONFIGURATION_SERVICE_REQUIRED = 'SPACES.CONFIGURATION_SERVICE_REQUIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=399125043
     */
    SELECT_SPACE = 'SPACES.SELECT_SPACE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=277251264
     * */
    EDIT_PAGE = 'SPACES.EDIT_PAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447953801
     */
    LEARNING = 'GLOBAL.LEARNING',
    /*
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=619312432
     */
    MICROSOFT_365_GROUP = 'SPACES.MICROSOFT_365_GROUP',
}

export const SPACES = { ...COMMUNITIES, ...SPACES_SPECIFIC_KEYS };

export const getCommunityPrivacy = (communityPrivacy: CommunityPrivacy) =>
    `COMMUNITY.PRIVACY_${snakeCase(communityPrivacy).toUpperCase()}`;

export enum CONTRIBUTION_FIELD {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=87623607
     */
    BUTTON_ARTICLE = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.BUTTON.ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=96545841
     */
    BUTTON_EVENT = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.BUTTON.EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80865886
     */
    BUTTON_FILE = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.BUTTON.FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80858792
     */
    BUTTON_IMAGE = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.BUTTON.IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=82248615
     */
    BUTTON_LINK = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.BUTTON.LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=83901113
     */
    BUTTON_VIDEO = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.BUTTON.VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=327299615
     */
    INSERT_MEDIA = 'CONTRIBUTION_FIELD.INSERT_MEDIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=303197325
     */
    TOOLTIP_ARTICLE = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.TOOLTIP.WRITE.ARTICLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=36962728
     */
    TOOLTIP_EVENT = 'FRONT.COMMUNITY.POST.CREATE.TYPE.EVENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80866407
     */
    TOOLTIP_FILE = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.TOOLTIP.FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=80866393
     */
    TOOLTIP_IMAGE = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.TOOLTIP.IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=82248733
     */
    TOOLTIP_LINK = 'FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.TOOLTIP.LINK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=282433283
     */
    TOOLTIP_POST = 'COMMUNITY.POST_CONTRIBUTION_FIELD_TOOLTIP_POST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=107146888
     */
    TOOLTIP_VIDEO = 'COMMUNITY.POST_CONTRIBUTION_FIELD_TOOLTIP_VIDEO',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=321814088
     */
    SPACE_TOOLTIP_FILE = 'CONTRIBUTION_FIELD.SPACE_TOOLTIP_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=321814041
     */
    SPACE_TOOLTIP_IMAGE = 'CONTRIBUTION_FIELD.SPACE_TOOLTIP_IMAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=321814173
     */
    SPACE_TOOLTIP_VIDEO = 'CONTRIBUTION_FIELD.SPACE_TOOLTIP_VIDEO',
}

export const getDescriptionForPostType = (type: string) =>
    `FRONT.COMMUNITY.POST.CONTRIBUTION_FIELD.PLACEHOLDER.${type}`;
